import React from 'react';
import styled from '@emotion/styled';

const StyledLoader = styled.div`
	&.loader-new:not(.defaultStyling) {
		position: absolute !important;
		left: 0;
		top: 0;
		z-index: 1;
		overflow: hidden;
		visibility: hidden;
		transition: background-color 0.4s ease-in-out 0.4s,
			background 0.4s ease-in-out 0.4s, visibility 0.4s ease-in-out 0.4s;
		background: rgba(255, 255, 255, 0.9);
		z-index: 1;
		height: 100% !important;
		width: 100%;
		min-height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 1 !important;

		&.small {
			min-height: 60px;

			.loader-new__inner {
				width: 35px;
				height: 35px;
			}
		}

		.loader-new__inner {
			opacity: 0;
			transition: opacity 0.4s ease-in-out 0s;
		}

		&.loading {
			background: rgba(255, 255, 255, 0.9);
			z-index: 1;
			visibility: visible;
			transition: background-color 0.4s ease-in-out, background 0.4s ease-in-out,
				visibility 0.4s ease-in-out, transform 0.4s ease-in-out;

			.loader-new__inner {
				opacity: 1;
				transition: opacity 0.4s ease-in-out 0.4s;
			}
			&.page-loader-modal {
				background: transparent;
			}
		}

		.loader-new__inner {
			width: 60px;
			height: 60px;
			stroke: ${({ theme }) => theme.colors.primary.dark};
			-webkit-animation: rotation 1.35s linear infinite;
			animation: rotation 1.35s linear infinite;
		}

		.loader-new__circle {
			stroke-width: 6;
			stroke-linecap: round;
			stroke-dasharray: 180;
			stroke-dashoffset: 0;
			-webkit-transform-origin: center;
			-ms-transform-origin: center;
			transform-origin: center;
			-webkit-animation: turn 1.35s ease-in-out infinite;
			animation: turn 1.35s ease-in-out infinite;
		}
	}

	@-webkit-keyframes rotation {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(270deg);
			transform: rotate(270deg);
		}
	}

	@keyframes rotation {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(270deg);
			transform: rotate(270deg);
		}
	}

	@-webkit-keyframes turn {
		0% {
			stroke-dashoffset: 180;
		}

		50% {
			stroke-dashoffset: 45;
			-webkit-transform: rotate(135deg);
			transform: rotate(135deg);
		}

		100% {
			stroke-dashoffset: 180;
			-webkit-transform: rotate(450deg);
			transform: rotate(450deg);
		}
	}

	@keyframes turn {
		0% {
			stroke-dashoffset: 180;
		}

		50% {
			stroke-dashoffset: 45;
			-webkit-transform: rotate(135deg);
			transform: rotate(135deg);
		}

		100% {
			stroke-dashoffset: 180;
			-webkit-transform: rotate(450deg);
			transform: rotate(450deg);
		}
	}
`;

function Loader({ additionalclass }) {
	return (
		<StyledLoader
			className={`loader-new hideInitially w-100 h-100 d-flex justify-content-center align-items-center ${additionalclass} `}
		>
			<svg
				className='loader-new__inner'
				viewBox='0 0 66 66'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle
					className='loader-new__circle'
					fill='none'
					cx='33'
					cy='33'
					r='30'
				></circle>
			</svg>
		</StyledLoader>
	);
}

export default Loader;
