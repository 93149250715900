import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import useCustomTheme from '../../../hooks/useCustomTheme';
import { Loader } from '../../molecules/loader';

const StyledButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: ${({ icon, iconPosition }) => {
		if (icon && iconPosition === 'left') {
			return 'row-reverse';
		} else {
			return 'row';
		}
	}};
	border-radius: ${({ theme }) => theme.button.borderRadius};
	cursor: pointer;
	font-family: ${({ theme }) => theme.button.fontFamily};
	letter-spacing: ${({ theme }) => theme.button.letterSpacing};
	line-height: ${({ theme }) => theme.button.lineHeight};
	font-weight: bold;
	pointer-events: ${({ loader }) => {
		return loader ? 'none' : 'auto';
	}};
	/**
		Button Size
	 */
	height: ${({ buttonSize, theme }) =>
		theme.button.buttonSize[buttonSize].height};
	font-size: ${({ buttonSize, theme }) =>
		theme.button.buttonSize[buttonSize].fontSize};
	padding: ${({ buttonSize, theme, icon, buttonType }) => {
		if (!icon && buttonType !== 'ghost') {
			return theme.button.buttonSize[buttonSize].padding;
		} else {
			return '0px 20px';
		}
	}};

	.btn-icon {
		padding: ${({ iconPosition }) =>
			iconPosition === 'right' ? '0px 0px 0px 20px' : '0px 15px 0px 0px'};
	}
	/**
		Button Type
	 */
	border: ${({ buttonType, theme }) =>
		theme.button.buttonType[buttonType].border};
	background-color: ${({ buttonType, theme }) =>
		theme.button.buttonType[buttonType].active.backgroundColor};
	color: ${({ buttonType, theme }) =>
		theme.button.buttonType[buttonType].active.textColor};

	svg,
	path {
		fill: ${({ buttonType, theme }) =>
			theme.button.buttonType[buttonType].active.textColor + '!important'};
	}
	&:disabled {
		border-color: ${({ buttonType, theme }) =>
			theme.button.buttonType[buttonType].disabled.borderColor};
		background-color: ${({ buttonType, theme }) =>
			theme.button.buttonType[buttonType].disabled.backgroundColor};
		color: ${({ buttonType, theme }) =>
			theme.button.buttonType[buttonType].disabled.textColor};
		cursor: not-allowed;
		pointer-events: none;
		svg,
		path {
			fill: ${({ buttonType, theme }) =>
				theme.button.buttonType[buttonType].disabled.textColor + '!important'};
		}
	}

	&:hover {
		text-decoration: none;
		background-color: ${({ buttonType, theme }) =>
			theme.button.buttonType[buttonType].hover.backgroundColor};
		color: ${({ buttonType, theme }) =>
			theme.button.buttonType[buttonType].hover.textColor};
		svg,
		path {
			fill: ${({ buttonType, theme }) =>
				theme.button.buttonType[buttonType].hover.textColor + '!important'};
		}
	}
	&:focus {
		text-decoration: none;
		outline: none;
		background-color: ${({ buttonType, theme }) =>
			theme.button.buttonType[buttonType].pressed.backgroundColor};
		color: ${({ buttonType, theme }) =>
			theme.button.buttonType[buttonType].pressed.textColor};

		svg,
		path {
			fill: ${({ buttonType, theme }) =>
				theme.button.buttonType[buttonType].pressed.textColor + '!important'};
		}
	}

	/* Loader styles inside button */
	.page-loader-modal {
		min-height: auto !important;
		svg {
			width: 28px !important;
			height: 28px !important;
			> circle {
				stroke: #ffffff !important;
			}
		}
	}
`;
const Button = ({
	children,
	className,
	onClick,
	disabled,
	buttonType,
	buttonSize,
	type,
	icon,
	iconPosition,
	cypressClass,
	loader
}) => {
	let theme = useCustomTheme();

	return (
		<StyledButton
			data-cy={cypressClass}
			className={className}
			disabled={disabled}
			onClick={onClick}
			type={type}
			buttonType={buttonType}
			buttonSize={buttonSize}
			theme={theme}
			icon={icon}
			iconPosition={iconPosition}
			loader={loader}
		>
			{loader ? (
				<Loader additionalclass='loading small page-loader-modal' />
			) : (
				<>
					{children}{' '}
					{icon && iconPosition ? <div className='btn-icon'>{icon}</div> : null}
				</>
			)}
		</StyledButton>
	);
};

Button.propTypes = {
	iconPosition: PropTypes.oneOf([null, 'left', 'right']),
	/**
	 * icon to display inside button
	 */
	icon: PropTypes.node,
	/**
	 * Children is the content displayed inside the button that you wrap this button component around
	 */
	children: PropTypes.node.isRequired,
	/**
	 * Adds a string of classnames to your button in order to style the button a bit different than it defaults to
	 */
	className: PropTypes.string,
	/**
	 * A callback function that you pass to the buttons onClick handler in order to take care of actions that you'd like to occur on the click of the button
	 */
	onClick: PropTypes.func,
	/**
	 * Disable the click function and update the styling of the button to appear un-clickable
	 */
	disabled: PropTypes.bool,
	/**
	 * Changes the appearance of the button in order to communicate different ideas to the customer
	 */
	buttonType: PropTypes.string,
	/**
	 * Changes the size of the button in order to communicate different ideas to the customer
	 */
	buttonSize: PropTypes.string,
	/**
	 * The type attribute specifies the type of HTML button whether it be a "button", "submit" or "reset" button.
	 */
	type: PropTypes.string,
	/**
	 * Cypress name
	 */
	cypressClass: PropTypes.string,
	/**
	 * Loader to show inside the button
	 */
	loader: PropTypes.bool
};

Button.defaultProps = {
	icon: null,
	iconPosition: null,
	children: null,
	className: '',
	onClick: () => {},
	disabled: false,
	buttonType: 'primary',
	buttonSize: 'default',
	type: 'button',
	cypressClass: 'btn',
	loader: false
};

export default Button;
