import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

const StyledRadioGroup = styled.div`
	display: flex;
	flex-wrap: wrap;
	border: none;
	padding: 0;
	margin: 0;
`;
const RadioGroup = ({
	children,
	className,
	groupName,
	register,
	onChange,
	selectedValue,
	cypressClass
}) => {
	const renderChildren = () => {
		return React.Children.map(children, (child) => {
			const childValue = child.props.value;
			return React.cloneElement(child, {
				name: groupName,
				onChange,
				isChecked: childValue === selectedValue,
				register
			});
		});
	};
	return (
		<StyledRadioGroup
			id={groupName}
			className={className}
			data-cy={cypressClass}
		>
			{renderChildren()}
		</StyledRadioGroup>
	);
};
RadioGroup.propTypes = {
	/**
	 * The radio buttons passed in to be part of this radio group
	 */
	children: PropTypes.node.isRequired,
	/**
	 * This value gives all child radio buttons the same name as the ID of this group, linking them properly
	 */
	groupName: PropTypes.string.isRequired,
	/**
	 * Callback fired when the state is changed. Will be passed to radio buttons as prop.
	 */
	onChange: PropTypes.func.isRequired,
	/**
	 * This value gives a className that can be used for styling as part of the form
	 */
	className: PropTypes.string,
	/**
	 * A function that gets passed for the useForm hook from react-hook-form to register an input
	 */
	register: PropTypes.func.isRequired,
	selectedValue: PropTypes.string.isRequired,
	/**
	 * Cypress name
	 */
	cypressClass: PropTypes.string
};

RadioGroup.defaultProps = {
	children: null,
	groupName: '',
	onChange: () => {},
	className: '',
	register: () => {},
	cypressClass: 'radio-group'
};

export default RadioGroup;
