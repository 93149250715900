import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

const StyledSvg = styled.svg`
	transform: ${({ angle }) => `rotate(${angle})`};
`;

const ChevronIcon = ({ width, height, viewBox, fill, direction }) => {
	let angle = '0deg';

	switch (direction) {
		case 'up':
			angle = '-90deg';
			break;
		case 'down':
			angle = '90deg';
			break;
		case 'left':
			angle = '180deg';
			break;

		default:
			angle = '0deg';
			break;
	}

	return (
		<StyledSvg
			width={width}
			height={height}
			viewBox={viewBox}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			angle={angle}
		>
			<path
				d='M1.70501 0L0.295013 1.41L4.87501 6L0.295013 10.59L1.70501 12L7.70501 6L1.70501 0Z'
				fill={fill}
			/>
		</StyledSvg>
	);
};

ChevronIcon.defaultProps = {
	width: '8',
	height: '12',
	viewBox: '0 0 8 12',
	fill: '#545454',
	direction: 'right'
};
ChevronIcon.propTypes = {
	/**
	 * the width of the icon
	 */
	width: PropTypes.string,
	/**
	 * the height of the icon
	 */
	height: PropTypes.string,
	/**
	 * the viewbox of the svg that wraps the icon
	 */
	viewBox: PropTypes.string,
	/**
	 * fill for the arrow
	 */
	fill: PropTypes.string,
	direction: PropTypes.oneOf(['left', 'right', 'up', 'down'])
};
export default ChevronIcon;
