import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { SelectInput } from '../../select_input';
import styled from '@emotion/styled';

const StyledStateSelect = styled.div``;
const StateSelectInput = ({
	register,
	errors,
	stateList,
	setValue,
	defaultValue,
	clearError,
	disabled,
	required,
	name
}) => {
	let error = false;
	let helperText = '';
	if (errors[name]) {
		error = true;
		helperText = errors[name].message;
	}

	const handleSelectChange = (itemObject) => {
		if (itemObject.value) {
			setValue(name, itemObject.value);
			clearError(name);
		}
	};

	const clearValues = () => {
		if (!required) {
			setValue(name, '');
			clearError(name);
		}
	};

	useEffect(() => {
		clearValues();
	}, []);

	// register custom select with react hooks
	useEffect(() => {
		register({ name }, { required: required ? 'Missing state' : false });
		clearValues();
	}, [register, required, name]);
	return (
		<StyledStateSelect>
			<SelectInput
				cypressClass='state-select'
				onChange={handleSelectChange}
				optionsList={stateList}
				label='State'
				helperText={helperText}
				error={error}
				value={defaultValue}
				disabled={disabled}
			/>
		</StyledStateSelect>
	);
};
StateSelectInput.propTypes = {
	/**
	 * Array containing all possible cities for selected postcode
	 */
	stateList: PropTypes.arrayOf(
		PropTypes.exact({
			option: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	).isRequired,
	/**
	 * Object containing the errors from this input field.
	 */
	errors: PropTypes.object,
	/**
	 * A function that gets passed for the useForm hook from react-hook-form to register an input
	 *
	 */
	register: PropTypes.func.isRequired,
	/**
	 * function to be used and consumed by react hook forms
	 */
	setValue: PropTypes.func.isRequired,
	/**
	 * clears hook errors
	 */
	clearError: PropTypes.func.isRequired,
	/**
	 * default value for select
	 */
	defaultValue: PropTypes.string,
	/**
	 * Set select input to a disabled state
	 */
	disabled: PropTypes.bool
};
StateSelectInput.defaultProps = {
	disabled: false,
	errors: {},
	stateList: [],
	defaultValue: null,
	setValue: () => {},
	register: () => {},
	clearError: () => {},
	name: 'state',
	required: true
};
export default StateSelectInput;
