import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TextInput } from '../../text_input';
import styled from '@emotion/styled';

const StyledDatePicker = styled.div`
	.react-datepicker {
		color: rgba(58, 53, 65, 0.87);
		border-radius: 6px;
		background-color: rgb(255, 255, 255);
		box-shadow: rgb(58 53 65 / 20%) 0px 4px 5px -2px,
			rgb(58 53 65 / 14%) 0px 7px 10px 1px, rgb(58 53 65 / 12%) 0px 2px 16px 1px;
		border: none;

		.react-datepicker__header {
			padding: 0px;
			border: none;
			font-weight: normal;
			background-color: rgb(255, 255, 255);
		}

		.react-datepicker__current-month {
			line-height: 2.1;
			font-size: 1rem;
			font-weight: normal;
			letter-spacing: 0.15px;
			margin-bottom: 0.5rem;
			color: rgba(58, 53, 65, 0.87);
		}

		> .react-datepicker__navigation.react-datepicker__navigation--previous {
			width: 24px;
			height: 24px;
			border: none;
			left: 15px;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHN0eWxlPSd3aWR0aDoyNHB4O2hlaWdodDoyNHB4JyB2aWV3Qm94PScwIDAgMjQgMjQnPjxwYXRoIGZpbGw9J3JnYmEoNTgsIDUzLCA2NSwgMC42KScgZD0nTTE1LjQxLDE2LjU4TDEwLjgzLDEyTDE1LjQxLDcuNDFMMTQsNkw4LDEyTDE0LDE4TDE1LjQxLDE2LjU4WicgLz48L3N2Zz4=');
		}

		.react-datepicker__navigation.react-datepicker__navigation--next {
			width: 24px;
			height: 24px;
			border: none;
			right: 15px;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHN0eWxlPSd3aWR0aDoyNHB4O2hlaWdodDoyNHB4JyB2aWV3Qm94PScwIDAgMjQgMjQnPjxwYXRoIGZpbGw9J3JnYmEoNTgsIDUzLCA2NSwgMC42KScgZD0nTTguNTksMTYuNThMMTMuMTcsMTJMOC41OSw3LjQxTDEwLDZMMTYsMTJMMTAsMThMOC41OSwxNi41OFonIC8+PC9zdmc+');
		}

		> .react-datepicker__navigation.react-datepicker__navigation--previous
			.react-datepicker__navigation-icon {
			display: none;
		}

		> .react-datepicker__navigation {
			top: 18px;
		}

		.react-datepicker__month-container {
			padding-top: 0.8rem;
		}

		.react-datepicker__header__dropdown
			.react-datepicker__month-dropdown-container,
		.react-datepicker__header__dropdown
			.react-datepicker__year-dropdown-container {
			margin-bottom: 1rem;
		}

		.react-datepicker__header__dropdown
			.react-datepicker__month-dropdown-container:not(:last-child) {
			/* margin-right: 2rem; */
			margin-right: 10px;
		}

		.react-datepicker__header__dropdown
			.react-datepicker__month-dropdown-container {
			padding-top: 0.8rem;
		}

		.react-datepicker__header__dropdown
			.react-datepicker__month-read-view--down-arrow,
		.react-datepicker__header__dropdown
			.react-datepicker__year-read-view--down-arrow {
			/* top: 4px;
			border-color: rgba(58, 53, 65, 0.38);
			right: -15px;
			width: 8px;
			height: 8px; */
			width: 25px;
			height: 25px;
			border: none;
			left: auto;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHN0eWxlPSd3aWR0aDoyNHB4O2hlaWdodDoyNHB4JyB2aWV3Qm94PScwIDAgMjQgMjQnPjxwYXRoIGZpbGw9J3JnYmEoNTgsIDUzLCA2NSwgMC42KScgZD0nTTE1LjQxLDE2LjU4TDEwLjgzLDEyTDE1LjQxLDcuNDFMMTQsNkw4LDEyTDE0LDE4TDE1LjQxLDE2LjU4WicgLz48L3N2Zz4=);
			-webkit-transform: rotate(270deg);
			-ms-transform: rotate(270deg);
			transform: rotate(270deg);
			padding: 0;
			background-repeat: no-repeat;
			top: -4px;
			right: -20px;
		}

		select.react-datepicker__month-select,
		select.react-datepicker__year-select {
			padding: 2px;
			border: 1px solid rgba(58, 53, 65, 0.38);
			border-radius: 3px;
		}

		.react-datepicker__header__dropdown
			.react-datepicker__month-dropdown-container {
			padding: 0;
		}

		.react-datepicker__header__dropdown
			.react-datepicker__month-read-view--selected-month,
		.react-datepicker__header__dropdown
			.react-datepicker__year-read-view--selected-year {
			font-size: 0.875rem;
			margin-right: 0.25rem;
			color: rgba(58, 53, 65, 0.87);
		}

		.react-datepicker__day {
			margin: 0px;
			width: 2.25rem;
			border-radius: 50%;
			line-height: 2.25rem;
			color: rgba(58, 53, 65, 0.87);
		}

		.react-datepicker__day-name {
			margin: 0px;
			line-height: 1.5;
			width: 2.25rem;
			font-size: 0.75rem;
			letter-spacing: 0.4px;
			color: rgba(58, 53, 65, 0.6);
		}

		.react-datepicker__header:not(.react-datepicker-year-header)
			+ .react-datepicker__month,
		.react-datepicker__header:not(.react-datepicker-year-header)
			+ .react-datepicker__year {
			margin: 1.5rem 0.8rem 0.8rem;
		}

		.react-datepicker__header__dropdown .react-datepicker__month-dropdown,
		.react-datepicker__header__dropdown .react-datepicker__year-dropdown {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			border-color: rgba(58, 53, 65, 0.12);
			border-radius: 6px;
			background-color: rgb(255, 255, 255);
			box-shadow: rgb(58 53 65 / 20%) 0px 5px 5px -3px,
				rgb(58 53 65 / 14%) 0px 8px 10px 1px,
				rgb(58 53 65 / 12%) 0px 3px 14px 2px;
		}

		.react-datepicker__header__dropdown .react-datepicker__month-option,
		.react-datepicker__header__dropdown .react-datepicker__year-option {
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
		}

		.react-datepicker__header__dropdown
			.react-datepicker__year-option
			.react-datepicker__navigation--years-upcoming {
			width: 9px;
			height: 9px;
			border-style: solid;
			border-width: 3px 3px 0px 0px;
			transform: rotate(-45deg);
			border-top-color: rgba(58, 53, 65, 0.38);
			border-right-color: rgba(58, 53, 65, 0.38);
			margin: 0.6875rem auto 0rem;
		}

		.react-datepicker__header__dropdown
			.react-datepicker__year-option
			.react-datepicker__navigation--years-previous {
			width: 9px;
			height: 9px;
			border-style: solid;
			border-width: 0px 0px 3px 3px;
			transform: rotate(-45deg);
			border-left-color: rgba(58, 53, 65, 0.38);
			border-bottom-color: rgba(58, 53, 65, 0.38);
			margin: 0rem auto 0.6875rem;
		}

		.react-datepicker__year-dropdown--scrollable {
			height: 250px;
		}

		.react-datepicker__day--selected,
		.react-datepicker__month--selected,
		.react-datepicker__year-text--selected,
		.react-datepicker__quarter--selected {
			color: rgb(255, 255, 255) !important;
			background-color: ${({ theme }) => theme.colors.primary.dark};
		}

		.react-datepicker__day--keyboard-selected,
		.react-datepicker__month-text--keyboard-selected,
		.react-datepicker__quarter-text--keyboard-selected,
		.react-datepicker__year-text--keyboard-selected {
			background-color: ${({ theme }) => theme.colors.primary.dark};
			opacity: 0.7;
			color: #fff;
		}

		.react-datepicker__day.react-datepicker__day--outside-month,
		.react-datepicker__day.react-datepicker__day--disabled:not(.react-datepicker__day--selected) {
			color: rgba(58, 53, 65, 0.38);
		}
	}

	.react-datepicker__navigation-icon--previous::before,
	.react-datepicker__navigation-icon--next::before {
		display: none;
	}

	.react-datepicker-popper {
		z-index: 9;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}
`;

const CustomDatePicker = ({
	defaultValue,
	register,
	setValue,
	clearError,
	errors,
	icon
}) => {
	const [startDate, setStartDate] = useState(
		new Date().setFullYear(new Date().getFullYear() - 18)
	);
	const [dateOfBirth, setDateOfBirth] = useState('');

	useEffect(() => {
		if (defaultValue) {
			setDateOfBirth(defaultValue);
			setValue('date', defaultValue);
		}
	}, [defaultValue]);

	const updateDOB = (date) => {
		let d = new Date(date),
			datestring =
				('0' + d.getDate()).slice(-2) +
				'/' +
				('0' + (d.getMonth() + 1)).slice(-2) +
				'/' +
				d.getFullYear();
		if (datestring) {
			setDateOfBirth(datestring);
			setValue('date', datestring);
			clearError('date');
		}
	};

	const CustomDOBInput = forwardRef(
		({ onClick, onChange, dateOfBirth = '' }, ref) => {
			return (
				<TextInput
					value={dateOfBirth}
					type='text'
					name='date'
					label='Date of Birth'
					error={errors?.date ? true : false}
					onClick={onClick}
					onChange={onChange}
					autoComplete='off'
					register={register({ required: 'Missing Date of Birth' })}
					maxLength={10}
					helperText={errors?.date ? 'Missing DOB' : ''}
					icon={icon}
					onIconClick={onClick}
				/>
			);
		}
	);

	return (
		<StyledDatePicker>
			<DatePicker
				register={register({ name: 'date', required: 'Missing DOB' })}
				customInput={<CustomDOBInput dateOfBirth={dateOfBirth} />}
				onChange={(date) => {
					updateDOB(date);
					setStartDate(date);
				}}
				dateFormat='dd/MM/yyyy'
				maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
				minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
				showMonthDropdown
				// useShortMonthInDropdown
				showYearDropdown
				onKeyDown={(e) => {
					e.preventDefault();
				}}
				yearDropdownItemNumber={50}
				scrollableYearDropdown
				showPopperArrow={false}
				selected={startDate}
				peekNextMonth
				dropdownMode='select'
			/>
		</StyledDatePicker>
	);
};

CustomDatePicker.defaultProps = {
	errors: {},
	register: () => {},
	setValue: () => {},
	clearError: () => {},
	icon: null
};
export default CustomDatePicker;
