import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SelectInput } from '../../select_input';
import { TextInput } from '../../text_input';

const StyledInput = styled.div``;
const DeliveryInstructionsInput = ({
	register,
	errors,
	setValue,
	defaultValue,
	clearError,
	disabled,
	unregister,
	country
}) => {
	const [showTextInput, setShowTextInput] = useState(false);
	const [selectedValue, setSelectedValue] = useState('');
	const [inputValue, setInputValue] = useState('');

	const maxLength = {
		value: 80,
		message: 'Please enter 80 characters or less'
	};
	const optionsList = [
		{
			option: 'No Delivery Instructions',
			value: 'reset'
		},
		{
			option: 'If no-one home, leave near the front door in a safe place',
			value: 'If no-one home, leave near the front door in a safe place'
		},
		// {
		// 	option: 'If no-one home, leave near the back door in a safe place',
		// 	value: 'If no-one home, leave near the back door in a safe place'
		// },
		{
			option: 'If no-one home, leave on verandah / porch',
			value: 'If no-one home, leave on verandah / porch'
		},
		{
			option: 'If no one home, leave at reception / concierge',
			value: 'If no one home, leave at reception / concierge'
		},
		{
			option: 'If no one home, leave in garage / carport',
			value: 'If no one home, leave in garage / carport'
		},
		{
			option: 'If no-one home, leave a calling card',
			value: 'If no-one home, leave a calling card'
		},
		{
			option: 'No safe place, signature required',
			value: 'No safe place, signature required'
		},
		{
			option: 'Business address, business hours only',
			value: 'Business address, business hours only'
		},
		{
			option: 'Other',
			value: 'other'
		}
	];

	let error,
		otherError = false;
	let helperText,
		OtherhelperText = null;
	if (errors.deliveryInstructions) {
		error = true;
		helperText = errors.deliveryInstructions.message;
	}

	if (errors.deliveryInstructionsOtherInput) {
		otherError = true;
		OtherhelperText = errors.deliveryInstructionsOtherInput.message;
	}

	const handleSelectChange = (itemObject) => {
		if (itemObject.value) {
			setSelectedValue(itemObject.value);
			if (itemObject.value !== 'other' && itemObject.value !== 'reset') {
				setValue('deliveryInstructions', itemObject.value);
				clearError('deliveryInstructions');
			}
		}
	};
	const handleInputChange = (e) => {
		let value = e.target.value;

		if (selectedValue === 'other' || country === 'NZ') {
			setInputValue(value);
			setValue('deliveryInstructions', 'other');
			setValue('deliveryInstructionsOtherInput', value);
			clearError('deliveryInstructions');
		}
	};

	// register custom select with react hooks
	useEffect(() => {
		let registerValidations = {
			required:
				selectedValue === 'other' ? 'Please enter delivery message' : false,
			maxLength: selectedValue === 'other' ? maxLength : false
		};

		register({ name: 'deliveryInstructions' });
		if (selectedValue === 'other' || country === 'NZ') {
			register({ name: 'deliveryInstructionsOtherInput' }, registerValidations);
		} else {
			unregister('deliveryInstructionsOtherInput');
			setInputValue('');
		}
	}, [register, selectedValue]);

	useEffect(() => {
		if (selectedValue === 'other' || country === 'NZ') {
			setShowTextInput(true);
		} else {
			if (selectedValue === 'reset') {
				setValue('deliveryInstructions', '');
				clearError('deliveryInstructions');
			}
			setShowTextInput(false);
		}
	}, [selectedValue]);

	useEffect(() => {
		if (defaultValue !== null && defaultValue.length > 0) {
			// check if its part of our options list
			const existingValue = optionsList.find(
				(selection) => selection.value === defaultValue
			);
			if (!existingValue || existingValue === undefined) {
				setSelectedValue('other');
				setInputValue(defaultValue.trim());
				setValue('deliveryInstructions', defaultValue.trim());
				setTimeout(() => {
					setValue('deliveryInstructionsOtherInput', defaultValue.trim());
				}, 200);
			} else {
				setSelectedValue(defaultValue);
			}
		}
	}, [defaultValue]);
	return (
		<StyledInput>
			{country !== 'NZ' && (
				<div className='form-row'>
					<SelectInput
						onChange={handleSelectChange}
						optionsList={optionsList}
						label='Delivery Instructions'
						helperText={helperText}
						error={error}
						value={selectedValue}
						disabled={disabled}
						cypressClass='delivery-instructions-select'
						name='deliveryInstructions'
					/>
				</div>
			)}

			{showTextInput ? (
				<div className='form-row'>
					<TextInput
						cypressClass='deliveryInstructionsOtherInput'
						onChange={handleInputChange}
						type='text'
						label='Please enter your delivery message'
						error={otherError}
						disabled={disabled}
						helperText={OtherhelperText}
						maxLength={80}
						value={inputValue}
						name='deliveryInstructionsOtherInput'
					/>
				</div>
			) : null}
		</StyledInput>
	);
};
DeliveryInstructionsInput.propTypes = {
	/**
	 * Object containing the errors from this input field.
	 */
	errors: PropTypes.object,
	/**
	 * A function that gets passed for the useForm hook from react-hook-form to register an input
	 *
	 */
	register: PropTypes.func.isRequired,
	/**
	 * function to be used and consumed by react hook forms
	 */
	setValue: PropTypes.func.isRequired,
	/**
	 * clears hook errors
	 */
	clearError: PropTypes.func.isRequired,
	/**
	 * default value for select
	 */
	defaultValue: PropTypes.string,
	/**
	 * Set select input to a disabled state
	 */
	disabled: PropTypes.bool,
	/**
	 * default value for select
	 */
	country: PropTypes.string.isRequired
};
DeliveryInstructionsInput.defaultProps = {
	disabled: false,
	errors: {},
	defaultValue: null,
	setValue: () => {},
	register: () => {},
	unregister: () => {},
	clearError: () => {},
	country: 'AU'
};
export default DeliveryInstructionsInput;
