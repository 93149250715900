import PropTypes from 'prop-types';
import React from 'react';
import { TextInput } from '../../text_input';
import styled from '@emotion/styled';

const StyledTextInput = styled(TextInput)`
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const PostalCodeInput = ({ register, errors, onChange, required, name }) => {
	let error = false;
	let helperText = '';
	if (errors[name]) {
		error = true;
		helperText = errors[name].message;
	}

	const handleOnChange = (e) => {
		const inputElement = e.target;
		const postalCodeInput = e.target.value.replace(/\D/g, '').substr(0, 4);
		inputElement.value = postalCodeInput;
		if (onChange) {
			// if on change method is passed, send the value as parameter.
			onChange(postalCodeInput);
		}
	};
	return (
		<StyledTextInput
			cypressClass='postal-code-input'
			helperText={helperText}
			error={error}
			onChange={handleOnChange}
			type='tel'
			label='Postcode'
			name={name}
			register={register({
				required: required ? 'Missing postcode' : false,
				minLength: {
					value: 4,
					message: 'Please make sure that your postcode is 4 numbers'
				}
			})}
			maxLength={4}
		/>
	);
};

PostalCodeInput.propTypes = {
	/**
	 * Object containing the errors from this input field.
	 */
	errors: PropTypes.object,
	/**
	 * passes input value to onChange method passed in as props
	 */
	onChange: PropTypes.func,
	/**
	 * A function that gets passed for the useForm hook from react-hook-form to register an input
	 *
	 */
	register: PropTypes.func.isRequired
};

PostalCodeInput.defaultProps = {
	errors: {},
	register: () => {},
	onChange: () => {},
	name: 'postalCode',
	required: true
};
export default PostalCodeInput;
