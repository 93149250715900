import React, { useRef, useState } from 'react';

import { CleanUpClassName } from '../../../utils/CleanUpClassName';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useCombinedRefs from '../../../hooks/useCombinedRefs';
import useCustomTheme from '../../../hooks/useCustomTheme';
import { useEffect } from 'react';

const StyledTextInput = styled.div`
	.input-label-container {
		display: inline-block;
		width: 100%;
		margin: 0;
		position: relative;
		background-color: inherit;
		.icon-pad {
			padding-right: 45px;
		}

		input {
			display: block;
			font-family: ${({ theme }) => theme.fonts.primary};
			font-size: 16px;
			line-height: 19px;
			color: rgba(0, 0, 0, 0.87);
			background-color: transparent;
			padding: 25px 12px 5px;
			width: 100%;
			height: 56px;
			box-sizing: border-box;
			border-radius: 4px 4px 0px 0px;
			border: 1px solid #e0e0e0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.87);
			background: #f8f8f8;
			transition: border 0.15s cubic-bezier(0.4, 0, 0.2, 1);

			:active {
				// border-bottom: 1px solid ${({ theme }) => theme.colors.primary.base};

				& + .input-label {
					// color: ${({ theme }) => theme.colors.primary.base};
				}
			}

			:focus {
				outline: none;
			}
		}

		.input-label {
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-align: left;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 1.2em;
			padding: 0px 12px;
			pointer-events: none;
			transform-origin: top left;
			font-size: 16px;
			line-height: 16px;
			transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
			color: ${({ theme }) => theme.input.labelColor};
			letter-spacing: 0.15px;
			font-weight: normal;
		}

		&.is-focused {
			&.floating-label {
				.input-label {
					// color: ${({ theme }) => theme.colors.primary.base};
					font-size: 12px;
					line-height: 12px;
					top: 10px;
				}
			}
			// &:not(.is-dirty) {
			// 	.label-text {
			// 		color: ${({ theme }) => theme.colors.primary.base};
			// 	}

			// 	input {
			// 		border-bottom: 1px solid ${({ theme }) => theme.colors.primary.base};
			// 	}
			// }
			&.is-dirty {
				.label-text {
					color: #545454;
				}
			}
		}
	}
	.error {
		input {
			border-bottom: 1px solid #cf004f !important;
		}
		.label-text {
			color: #cf004f !important;
		}
		.helper-text,
		.input-label,
		.is-focused .label-text {
			color: #cf004f !important;
		}
	}

	.helper-text {
		font-weight: 300;
		font-size: 13px;
		color: #9e9e9e;
		display: flex;
		align-items: center;
		letter-spacing: 0.25px;
		padding-left: 12px;
		margin-top: 6px;
	}

	.hide-placeholder {
		&::placeholder {
			color: transparent;
		}
		&::-webkit-input-placeholder {
			color: transparent;
		}
		&:-ms-input-placeholder {
			color: transparent !important;
		}
		&::-moz-placeholder {
			color: transparent;
		}
	}
	.icon-container {
		position: absolute;
		right: 15px;
		top: 15px;
		width: 26px;
		height: 26px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
const TextInput = ({
	className,
	error,
	disabled,
	maxLength,
	autoComplete,
	name,
	onChange,
	onIconClick,
	placeholder,
	register,
	type,
	helperText,
	label,
	icon,
	cypressClass,
	readOnly,
	onBlur = () => {},
	onFocus = () => {},
	onPaste = () => {},
	...props
}) => {
	let textInput = useRef();
	let combinedRef = useCombinedRefs(textInput, register);

	const errorClass = 'error';
	const containerClassName = 'input-label-container floating-label';
	const [containerClass, setContainerClass] = useState(containerClassName);
	const [inputClassName, setInputClassName] = useState(className);
	let theme = useCustomTheme();
	let wrapperClassName = 'input-wrapper';

	if (error) {
		wrapperClassName += ` ${errorClass}`;
	}

	useEffect(() => {
		if (placeholder && label) {
			setInputClassName(inputClassName + ' hide-placeholder');
		}
	}, []);
	/**
	 * on Blur
	 */
	const onBlurChange = (e) => {
		let inputValue = e.target.value;
		if (inputValue.length === 0) {
			setContainerClass(containerClass.replace('is-focused', ''));
		}
		if (
			placeholder &&
			inputClassName.indexOf('hide-placeholder') < 0 &&
			label
		) {
			setInputClassName(inputClassName + ' hide-placeholder');
		}

		if (onBlur) {
			onBlur(e);
		}
	};
	/**
	 *
	 * On Focus
	 */
	const onFocusChange = (e) => {
		if (label && containerClass.indexOf('is-focused') < 0) {
			setContainerClass(containerClass + ' is-focused');
			setInputClassName(inputClassName.replace('hide-placeholder', ''));
		}

		if (onFocus) {
			onFocus(e);
		}
	};

	/**
	 * On Change
	 *
	 */

	const onInputChange = (e) => {
		let inputValue = e.target.value;
		if (inputValue.length > 0 && containerClass.indexOf('is-dirty') < 0) {
			setContainerClass(containerClass + ' is-dirty');
		} else if (inputValue.length === 0) {
			setContainerClass(containerClass.replace('is-dirty', ''));
			setInputClassName(inputClassName.replace('hide-placeholder', ''));
		}
		if (onChange) {
			onChange(e);
		}
	};

	/**
	 * Default Value
	 */
	useEffect(() => {
		if (combinedRef.current && combinedRef.current.value) {
			if (label) {
				setContainerClass(containerClass + ' is-focused is-dirty');
			}
			if ((placeholder && !label) || (!placeholder && !label)) {
				setContainerClass(containerClass + ' is-dirty');
			}
		}
	}, [combinedRef]);

	// useEffect(() => {
	// 	const checkAutoFill = setTimeout(() => {
	// 		if (
	// 			document
	// 				.getElementsByName('email')[0]
	// 				?.matches(':-internal-autofill-selected') ||
	// 			document
	// 				.getElementsByName('password')[0]
	// 				?.matches(':-internal-autofill-selected')
	// 		) {
	// 			if (label) {
	// 				setContainerClass(containerClass + ' is-focused is-dirty');
	// 			}

	// 			if ((placeholder && !label) || (!placeholder && !label)) {
	// 				setContainerClass(containerClass + ' is-dirty');
	// 			}
	// 		}
	// 	}, 500);

	// 	return function cleanup() {
	// 		clearTimeout(checkAutoFill);
	// 	};
	// }, []);

	useEffect(() => {
		if (icon && inputClassName.indexOf('icon-pad') < 0) {
			setInputClassName((prevName) => {
				return prevName + ' icon-pad';
			});
		}
		// else {
		// 	setInputClassName((prevName) => prevName.replace('icon-pad', ''));
		// }
	}, [icon]);

	const finalInputClassName = CleanUpClassName(inputClassName);
	const finalContainerClass = CleanUpClassName(containerClass);
	const finalWrapperClassName = CleanUpClassName(wrapperClassName);
	return (
		<StyledTextInput theme={theme}>
			<div className={finalWrapperClassName}>
				<div className={finalContainerClass}>
					{label ? (
						<label className='input-label'>
							<span className='label-text'>{label}</span>
						</label>
					) : null}
					{icon ? (
						<div
							className={`icon-container ${
								onIconClick ? 'cursor-pointer' : ''
							}`}
							onClick={onIconClick}
						>
							{icon}
						</div>
					) : null}
					<input
						autoComplete={autoComplete}
						data-cy={cypressClass ? cypressClass : name}
						className={finalInputClassName}
						disabled={disabled}
						maxLength={maxLength}
						name={name}
						onChange={onInputChange}
						placeholder={placeholder}
						ref={combinedRef}
						readOnly={readOnly}
						onBlur={onBlurChange}
						onFocus={onFocusChange}
						onPaste={onPaste}
						type={type}
						{...props}
					/>
				</div>
				{helperText ? (
					<label className='helper-text'>{helperText}</label>
				) : null}
			</div>
		</StyledTextInput>
	);
};

TextInput.propTypes = {
	/**
	 * Class names to append to the input element
	 */
	className: PropTypes.string,
	/**
	 * If true, the input element will be disabled.
	 */
	disabled: PropTypes.bool,
	/**
	 * Boolean that determines whether to add error styling
	 */
	error: PropTypes.bool,
	/**
	 * text to display below the text input
	 */
	helperText: PropTypes.string,
	/**
	 * The text input label
	 */
	label: PropTypes.string,
	/**
	 * Name attribute of the input element.
	 */

	maxLength: PropTypes.number,
	/**
	 * The maximum length of characters for the input field
	 */
	autoComplete: PropTypes.string,
	/**
	 * AutoComplete On/Off for input field
	 */
	name: PropTypes.string,
	/**
	 * Callback fired when the value is changed
	 */
	onChange: PropTypes.func,
	/**
	 * Placeholder text to display before user enters a value
	 */
	placeholder: PropTypes.string,
	/**
	 *
	 * Method that allows you to register input Ref and validation rules into React Hook Form.
	 */
	register: PropTypes.func,
	/**
	 * Type of the input element. It should be a valid HTML5 input type.
	 */
	type: PropTypes.string.isRequired,
	icon: PropTypes.node,
	/**
	 * Cypress name
	 */
	cypressClass: PropTypes.string,
	/**
	 * Boolean that determines whether to make the field readonly
	 */
	readOnly: PropTypes.bool
};
TextInput.defaultProps = {
	className: '',
	error: false,
	disabled: false,
	helperText: null,
	type: 'text',
	name: 'text-input',
	placeholder: null,
	label: null,
	icon: null,
	register: () => {},
	cypressClass: null,
	readOnly: false,
	onIconClick: () => {}
};
export default TextInput;
