import { Modal } from '../modal';
import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from '../spinner';
import styled from '@emotion/styled';
import { Loader } from '../loader';

const StyledLoaderContent = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 40%;
`;

const PageLoader = ({ showLoader }) => {
	return (
		<Modal
			showModal={showLoader}
			disableClose
			disableOnClickOutside
			backgroundColor='none'
			cypressClass='page-loader-modal'
		>
			<StyledLoaderContent>
				<Loader additionalclass='loading page-loader-modal' />
			</StyledLoaderContent>
		</Modal>
	);
};

PageLoader.propTypes = {
	showLoader: PropTypes.bool
};

PageLoader.defaultProps = {
	showLoader: false
};
export default PageLoader;
