import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { SelectInput } from '../../select_input';
import styled from '@emotion/styled';

const StyledCitySelect = styled.div``;
const CitySelectInput = ({
	register,
	errors,
	cityList,
	setValue,
	defaultValue,
	clearError,
	disabled,
	required,
	name
}) => {
	let error = false;
	let helperText = '';
	if (errors[name]) {
		error = true;
		helperText = errors[name].message;
	}

	const handleSelectChange = (itemObject) => {
		if (itemObject.value) {
			setValue(name, itemObject.value);
			clearError(name);
		}
	};

	const clearValues = () => {
		if (!required) {
			setValue(name, '');
			clearError(name);
		}
	};

	useEffect(() => {
		clearValues();
	}, []);

	// register custom select with react hooks
	useEffect(() => {
		register({ name }, { required: required ? 'Missing city' : false });
		clearValues();
	}, [register, required, name]);
	return (
		<StyledCitySelect>
			<SelectInput
				cypressClass='city-select'
				onChange={handleSelectChange}
				optionsList={cityList}
				label='City'
				helperText={helperText}
				error={error}
				value={defaultValue}
				disabled={disabled}
			/>
		</StyledCitySelect>
	);
};
CitySelectInput.propTypes = {
	/**
	 * Array containing all possible cities for selected postcode
	 */
	cityList: PropTypes.arrayOf(
		PropTypes.exact({
			option: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	).isRequired,
	/**
	 * Object containing the errors from this input field.
	 */
	errors: PropTypes.object,
	/**
	 * A function that gets passed for the useForm hook from react-hook-form to register an input
	 *
	 */
	register: PropTypes.func.isRequired,
	/**
	 * function to be used and consumed by react hook forms
	 */
	setValue: PropTypes.func.isRequired,
	/**
	 * clears hook errors
	 */
	clearError: PropTypes.func.isRequired,
	/**
	 * default value for select
	 */
	defaultValue: PropTypes.string,
	/**
	 * Set select input to a disabled state
	 */
	disabled: PropTypes.bool
};
CitySelectInput.defaultProps = {
	disabled: false,
	errors: {},
	cityList: [],
	defaultValue: null,
	setValue: () => {},
	register: () => {},
	clearError: () => {},
	name: 'city',
	required: true
};
export default CitySelectInput;
