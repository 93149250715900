import {
	BrandDetailsProvider,
	BrandThemeProvider
} from 'dw-au-react-components';
import React from 'react';
import ReactDOM from 'react-dom';
import { ProductCarouselProvider } from './context/ProductCarouselProvider';
import ProductCarousel from './pages/Carousel';

let brandTag = 'wpe';

const rootElements = document.querySelectorAll(
	"[data-key='apac_product_carousel']"
); //document.getElementById('product_carousel') ;

rootElements.forEach((rootElement) => {
	const sectionName = rootElement.getAttribute('data-sectionName');
	const slug = rootElement.getAttribute('data-slug');
	const sectionTitle = rootElement.getAttribute('data-sectionTitle');

	const finalRootElement = sectionName
		? `product_carousel_${sectionName}`
		: 'product_carousel';

	if (sectionName) {
		rootElement.setAttribute('id', finalRootElement);
	}

	const App = () => {
		return (
			<BrandDetailsProvider brandTag={brandTag}>
				<BrandThemeProvider brandTag={brandTag}>
					<ProductCarouselProvider sectionName={sectionName} slug={slug}>
						<ProductCarousel title={sectionTitle} />
					</ProductCarouselProvider>
				</BrandThemeProvider>
			</BrandDetailsProvider>
		);
	};
	ReactDOM.render(<App />, document.getElementById(`${finalRootElement}`));
});
