import React, { useEffect, useRef, useState } from 'react';

import { ArrowIcon } from '../../atoms/icons/arrow_icon';
import { CleanUpClassName } from '../../../utils/CleanUpClassName';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useCustomTheme from '../../../hooks/useCustomTheme';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

const SelectInputWrapper = styled.div`
	.hidden {
		display: none;
	}
	.disabled {
		.box {
			.select {
				cursor: default;
			}
		}
	}
	.helper-text {
		font-weight: 300;
		font-size: 13px;
		color: #9e9e9e;
		display: flex;
		align-items: center;
		letter-spacing: 0.25px;
		padding-left: 12px;
		margin-top: 6px;
	}

	.box {
		width: 100%;
		position: relative;

		.select-label,
		.select-value {
			width: 95%;
			overflow: hidden;
			white-space: nowrap;
			text-align: left;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
			top: 0;
			display: flex;
			align-items: center;
			pointer-events: none;
			transform-origin: top left;
			transition: all 0.1s cubic-bezier(0.4, 0, 0.24, 1);
			font-size: 16px;
			line-height: 16px;

			.label-text {
				padding: 0px 12px;
				text-overflow: ellipsis;
				overflow: hidden;
				color: ${({ theme }) => theme.dropdown.labelColor};
			}
		}
		.select-value {
			top: 15px;
			.label-text {
				color: #212121;
			}
		}
		&.active {
			.select-label {
				transform: translate(10px, -15%) scale(0.8);

				.label-text {
					padding: 0px 5px;
					color: #545454;
				}
			}
			.select {
				border-bottom: 1px solid #000000;
			}
		}

		.arrow {
			position: absolute;
			display: flex;
			align-items: center;
			right: 0;
			height: 56px;
			top: 0;
			padding: 0 12px;
			cursor: pointer;
			&.arrow-down {
				transform: rotate(0deg);
			}
			&.arrow-up {
				transform: rotate(180deg);
			}
		}
		.select {
			cursor: pointer;
			color: #212121;
			padding: 15px;
			height: 56px;
			box-sizing: border-box;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			border: 1px solid #e0e0e0;
			border-bottom: 1px solid #000000;
			background: #f8f8f8;
			position: relative;
			border-radius: 4px 4px 0px 0px;
			.dropdown {
				z-index: 9;
				border-radius: 3px;
				box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
				background: #ffffff;
				color: #666666;
				max-height: 190px;
				list-style: none;
				padding: 0;
				position: absolute;
				left: 0;
				top: 56px;
				width: 100%;
				overflow-y: scroll;
				box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%),
					0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
				padding: 8px 0;
				/** 	::-webkit-scrollbar {
					width: 0px; // Remove scrollbar space 
					background: transparent; // Optional: just make scrollbar invisible 
				}
				// Optional: show position indicator in red 
				::-webkit-scrollbar-thumb {
					background: #ff0000;
				} */
				.dropdown-option {
					cursor: pointer;

					height: 36px;
					/* padding: 0 12px; */
					display: flex;
					align-items: center;
					overflow: hidden;
					white-space: nowrap;
					text-align: left;
					padding: 0 16px;
					cursor: pointer;
					.dropdown-text {
						width: 90%;
						text-overflow: ellipsis;
						overflow: hidden;
						color: ${({ theme }) => theme.dropdown.optionColor};
						cursor: pointer;
						font-size: 15px;
					}
					:hover {
						background: #f2f2f2;
						color: ${({ theme }) => theme.dropdown.optionHoverTextColor};
					}
				}
			}
		}
	}

	.error {
		.box .select,
		.box .select:hover {
			border-bottom: 2px solid #cf004f;
		}
		.helper-text {
			color: #cf004f;
		}

		.box {
			&.active {
				.select-label .label-text {
					color: #cf004f;
				}
			}
		}
	}
`;
const SelectInput = ({
	error,
	className,
	helperText,
	disabled,
	label,
	onChange,
	onClose,
	onOpen,
	optionsList,
	value,
	cypressClass
}) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const [selectedValue, setSelectedValue] = useState(false);
	const [arrowClass, setArrowClass] = useState('arrow arrow-down');
	const selectBoxClassName = 'box';
	const [selectWrapperClass, setSelectWrapperClass] = useState(
		selectBoxClassName
	);

	const [selectContainerClass, setSelectContainerClass] = useState(
		'select-container'
	);
	const dropdownRef = useRef();
	const selectRef = useRef();
	useOnClickOutside(
		dropdownRef,
		showDropdown
			? () => {
					handleClose();
			  }
			: () => {}
	);

	useOnClickOutside(
		selectRef,
		!showDropdown
			? () => {
					setSelectWrapperClass((prevClass) => {
						return prevClass.replace('focused', '');
					});
			  }
			: () => {}
	);

	const handleClose = () => {
		setShowDropdown(false);
		if (onClose) {
			onClose();
		}
	};
	const handleClick = () => {
		// open dropdown

		if (!showDropdown && !disabled) {
			setShowDropdown(true);
			if (onOpen) {
				onOpen();
			}
		}
	};
	const handleChange = (item) => {
		setSelectedValue(item);
		if (onChange) {
			// pass the selected item to the onChange function
			onChange(item);
		}

		setSelectWrapperClass((prevClass) => {
			if (prevClass.indexOf('focused') < 0) {
				return prevClass + ' focused';
			}
			return prevClass;
		});
		handleClose();
	};

	useEffect(() => {
		// set default value
		if (value) {
			let filteredList = optionsList.filter((item) => {
				return item.value === value;
			});

			if (filteredList.length === 1) {
				setSelectedValue(filteredList[0]);
			} else {
				setSelectedValue(false);
			}
		} else {
			setSelectedValue(false);
		}
	}, [value, optionsList]);

	useEffect(() => {
		// class modification logic
		if (!selectedValue) {
			setSelectWrapperClass((prevClass) => prevClass.replace('active', ''));
		}
		if (label && (showDropdown || selectedValue)) {
			setSelectWrapperClass((prevClass) => {
				let newClass = prevClass;
				if (prevClass.indexOf('active') < 0) {
					newClass += ' active';
				}

				return newClass;
			});
		}

		if (showDropdown) {
			// rotate arrow
			setArrowClass((prevClass) => {
				return prevClass.replace('down', 'up');
			});
		} else {
			setArrowClass((prevClass) => {
				return prevClass.replace('up', 'down');
			});
		}

		if (error) {
			setSelectContainerClass((prevClass) => {
				if (prevClass.indexOf('error') < 0) {
					return prevClass + ' error';
				}
				return prevClass;
			});
		} else {
			setSelectContainerClass((prevClass) => {
				return prevClass.replace('error', '');
			});
		}

		if (disabled) {
			setSelectContainerClass((prevClass) => {
				if (prevClass.indexOf('disabled') < 0) {
					return prevClass + ' disabled';
				}
				return prevClass;
			});
		} else {
			setSelectContainerClass((prevClass) => {
				return prevClass.replace('disabled', '');
			});
		}

		if (className) {
			setSelectContainerClass((prevClass) => {
				if (prevClass.indexOf(className) < 0) {
					return prevClass + ' ' + className;
				}
				return prevClass;
			});
		}
	}, [showDropdown, selectedValue, error, className, disabled, label]);

	const renderOptions = () => {
		let options = [];

		optionsList.map((item, idx) => {
			options.push(
				<div
					className='dropdown-option'
					onClick={() => handleChange(item)}
					key={idx}
					data-value={item.value}
					data-cy={`${cypressClass}-value-${item.value}`}
				>
					<label className='dropdown-text'>{item.option}</label>
				</div>
			);
		});
		return options;
	};

	const finalSelectWrapperClass = CleanUpClassName(selectWrapperClass);
	const finalSelectContainerClass = CleanUpClassName(selectContainerClass);
	let theme = useCustomTheme();
	return (
		<SelectInputWrapper theme={theme} showDropdown={showDropdown}>
			<div className={finalSelectContainerClass}>
				<div className={finalSelectWrapperClass}>
					{label ? (
						<label className='select-label'>
							<span className='label-text'>{label}</span>
						</label>
					) : null}
					{selectedValue ? (
						<label className='select-value'>
							<span className='body-5 label-text'>{selectedValue.option}</span>
						</label>
					) : null}
					<div
						data-cy={cypressClass}
						className='select'
						onClick={handleClick}
						data-value={selectedValue.value}
						ref={selectRef}
					>
						{showDropdown && !disabled ? (
							<div
								className='dropdown'
								ref={dropdownRef}
								data-cy={`${cypressClass}-dropdown`}
							>
								{renderOptions()}
							</div>
						) : null}
					</div>

					{!disabled ? (
						<div className={arrowClass} onClick={handleClick}>
							<ArrowIcon />
						</div>
					) : null}
				</div>
				{helperText ? (
					<label className='helper-text'>{helperText}</label>
				) : null}
			</div>
		</SelectInputWrapper>
	);
};

SelectInput.propTypes = {
	/**
	 * Class names to append to select container
	 */
	className: PropTypes.string,
	/**
	 * If true, the select will be disabled.
	 */
	disabled: PropTypes.bool,
	/**
	 * Boolean that determines whether to add error styling
	 */
	error: PropTypes.bool,
	/**
	 * The value that gets displayed to the user next to the select input
	 */
	label: PropTypes.string,
	/**
	 * Name attribute of the input element.
	 */
	name: PropTypes.string,
	/**
	 * text to display below the text input
	 */
	helperText: PropTypes.string,
	/**
	 * The function that will be sent the React event whenever the event is clicked and an item is selected.
	 */
	onChange: PropTypes.func,
	/**
	 *
	 *  A list of options with respective values.
	 */
	optionsList: PropTypes.arrayOf(
		PropTypes.exact({
			option: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	).isRequired,
	/**
	 * Value to give to select for automatic selection
	 */
	value: PropTypes.string,
	/**
	 * Cypress name
	 */
	cypressClass: PropTypes.string
};
SelectInput.defaultProps = {
	className: '',
	error: false,
	disabled: false,
	label: null,
	helperText: null,
	onChange: () => {},
	onClose: () => {},
	onOpen: () => {},
	optionsList: [],
	value: '',
	cypressClass: 'select-dropdown'
};
export default SelectInput;
