import { CheckIcon } from '../../atoms/icons/check_icon';
import { CleanUpClassName } from '../../../utils/CleanUpClassName';
import { CloseIcon } from '../../atoms/icons/close_icon';
import { Divider } from '../../atoms/divider';
import { EditIcon } from '../../atoms/icons/edit_icon';
import { EmotionBreakpoint } from '../../../utils/EmotionBreakpoint';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import useCustomTheme from '../../../hooks/useCustomTheme';

const StyledCardContainer = styled.div`
	background-color: #ffffff;
	height: auto;
	border: 1px solid #e3e3e3;
	transition: all 0.3s ease-in-out;

	.header,
	.content {
		padding: 20px;
	}
	.content {
		border-top: 1px solid #e3e3e3;
	}
	.header h6 {
		transition: all 0.3s ease-in-out;
	}
	&.complete {
		background-color: ${({ theme }) => theme.colors.base};
		box-sizing: border-box;
		border-radius: 3px;
		border-color: ${({ theme }) => theme.colors.primary.base};
		.header h6 {
			color: ${({ theme }) => theme.colors.primary.base};
		}
	}
`;

const StyledCardTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	.left-column {
		display: flex;
		align-items: center;
		.check {
			margin: 0px 15px;
		}
		h3 {
			margin: 0px;
		}
	}

	.edit,
	.close {
		cursor: pointer;
		position: relative;
		z-index: 1;

		:before {
			content: '';
			position: absolute;
			height: 40px;
			width: 40px;
			background-color: transparent;
			transition: background-color 0.3s ease-in-out;
			border-radius: 50%;
			z-index: -1;
			top: -7px;
		}

		:hover {
			:before {
				background-color: rgba(0, 0, 0, 0.07);
			}
		}
	}

	.edit:before {
		left: -8px;
	}

	.close:before {
		left: -12px;
	}
`;

/**
 *
 * Will render children inside a card
 */
const CardContainer = ({
	children,
	title,
	isComplete,
	addDivider,
	className,
	editable,
	showCloseButton,
	onEditClick,
	onCloseClick,
	containerId,
	cypressClass
}) => {
	let updatedCardClassName = className;

	if (isComplete) {
		updatedCardClassName += ' complete';
	}
	let theme = useCustomTheme();

	const Header = () => {
		return (
			<>
				<StyledCardTitle>
					<div className='left-column'>
						<h6
							className={`mb-0 ${theme.brandTag === 'vir' ? 'lower' : ''}`}
							data-cy={`${cypressClass}-title`}
							title={title}
						>
							{title}
						</h6>
						{/* {isComplete ? (
							<div className='check' title='Section Completed'>
								<CheckIcon />
							</div>
						) : null} */}
					</div>
					{editable && !showCloseButton ? (
						<div
							className='edit'
							onClick={onEditClick}
							data-cy={`${cypressClass}-edit`}
							title='Click to edit this section'
						>
							<EditIcon />
						</div>
					) : null}
					{showCloseButton && editable ? (
						<div
							className='close'
							onClick={onCloseClick}
							data-cy={`${cypressClass}-close`}
							title='Click to close this section'
						>
							<CloseIcon />
						</div>
					) : null}
				</StyledCardTitle>
				{addDivider ? <Divider /> : null}
			</>
		);
	};

	const finalCardClassName = CleanUpClassName(updatedCardClassName);
	return (
		<StyledCardContainer
			className={finalCardClassName}
			id={containerId}
			data-cy={cypressClass}
			theme={theme}
		>
			<div className='header'>{Header()}</div>
			{children && <div className='content'>{children}</div>}
		</StyledCardContainer>
	);
};

CardContainer.defaultProps = {
	addDivider: false,
	isComplete: false,
	children: null,
	title: 'Sample Title',
	className: '',
	containerId: null,
	editable: false,
	showCloseButton: false,
	onCloseClick: () => {},
	onEditClick: () => {},
	cypressClass: 'card-container'
};
CardContainer.propTypes = {
	/**
	 * Id for the card container
	 */
	containerId: PropTypes.string,
	/**
	 * The content to display inside the card
	 */
	children: PropTypes.node,
	/**
	 * Boolean used to determine if Delivery Instructions Module is complete to update styling.
	 */
	isComplete: PropTypes.bool.isRequired,
	/**
	 * the title of the card
	 */
	title: PropTypes.string.isRequired,
	/**
	 *
	 *  Boolean used to determine whether to add a border/hr rule between the title and content.
	 */
	addDivider: PropTypes.bool.isRequired,
	/**
	 * ClassName for the div container
	 */
	className: PropTypes.string,
	/**
	 * Boolean to determine if card is editable or not
	 */
	editable: PropTypes.bool,
	/**
	 * function to execute when edit button is clicked
	 */
	onEditClick: PropTypes.func,
	/**
	 * Boolean to determine if the close button should render
	 */
	showCloseButton: PropTypes.bool,
	/**
	 * function to execute when close button is clicked
	 */
	onCloseClick: PropTypes.func,
	/**
	 * Cypress name
	 */
	cypressClass: PropTypes.string
};
export default CardContainer;
