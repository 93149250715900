import { AmericanExpressIcon } from '../../atoms/icons/american_express_icon';
import { MasterCardIcon } from '../../atoms/icons/mastercard_icon';
import PropTypes from 'prop-types';
import React from 'react';
import { VisaIcon } from '../../atoms/icons/visa_icon';
import styled from '@emotion/styled';
const StyledCardIcon = styled.div``;

const CreditCardIcon = ({
	className,
	active,
	cardType,
	width,
	height,
	viewBox
}) => {
	const renderCard = () => {
		switch (cardType) {
			case 'AmericanExpress':
				return (
					<AmericanExpressIcon
						width={width}
						height={height}
						viewBox={viewBox}
						isDark={!active}
					/>
				);
			case 'Visa':
				return (
					<VisaIcon
						width={width}
						height={height}
						viewBox={viewBox}
						isDark={!active}
					/>
				);
			case 'MasterCard':
				return (
					<MasterCardIcon
						width={width}
						height={height}
						viewBox={viewBox}
						isDark={!active}
					/>
				);

			default:
				break;
		}
	};

	return (
		<StyledCardIcon
			className={className}
			data-cy={`${cardType.toLowerCase()}-icon`}
		>
			{renderCard()}
		</StyledCardIcon>
	);
};
CreditCardIcon.defaultProps = {
	active: true,
	width: '48',
	height: '48',
	viewBox: '0 0 48 48',
	className: ''
};
CreditCardIcon.propTypes = {
	className: PropTypes.string,
	/**
	 * determines what theme the credit card will be displayed in
	 */
	active: PropTypes.bool,
	/**
	 * The value of the credit card to render
	 */
	cardType: PropTypes.oneOf(['AmericanExpress', 'Visa', 'MasterCard'])
		.isRequired,
	/**
	 * the width of the icon
	 */
	width: PropTypes.string,
	/**
	 * the height of the icon
	 */
	height: PropTypes.string,
	/**
	 * the viewbox of the svg that wraps the icon
	 */
	viewBox: PropTypes.string
};
export default CreditCardIcon;
