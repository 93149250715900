import React from 'react';

function ProductImage({
	productName = '',
	productVintage = '',
	prodImage,
	itemCode = ''
}) {
	return (
		<div className='image__container position-relative d-flex align-items-center justify-content-center mb-15'>
			{itemCode ? (
				<a
					className='product__img-link'
					target='_blank'
					rel='noreferrer'
					href={`/product/${productName.split(' ').join('-')}${
						productVintage != '' ? '-' + productVintage : ''
					}/${itemCode}`}
				>
					<img
						className='img-responsive product__image mx-auto'
						src={prodImage}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = '/images/au/common/new_default_bottle_thumb.png';
						}}
						alt={productName + ' ' + productVintage}
						title={productName + ' ' + productVintage}
						loading='lazy'
					/>
				</a>
			) : (
				<img
					className='img-responsive product__image mx-auto'
					src={prodImage}
					onError={(e) => {
						e.target.onerror = null;
						e.target.src = '/images/au/common/new_default_bottle_thumb.png';
					}}
					alt={productName + ' ' + productVintage}
					title={productName + ' ' + productVintage}
					loading='lazy'
				/>
			)}
		</div>
	);
}

export default ProductImage;
