import React, { useEffect, useState } from 'react';

import { CleanUpClassName } from '../../../utils/CleanUpClassName';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledRadioButton = styled.label`
	display: flex;
	align-items: center;
	margin-right: 15px;
	&.disabled {
		opacity: 0.5;
	}
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	.label {
		padding-left: 10px;
	}
	/* Hide the browser's default checkbox */
	input {
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;
	}
	.radio-border {
		align-items: center;
		justify-content: center;
		display: flex;

		/** Theme Styles */
		height: 20px;
		width: 20px;
		border-radius: 50%;
		border: 2px solid rgba(0, 0, 0, 0.54);
	}

	input:checked ~ .radio-border .radio-background {
		align-items: center;
		justify-content: center;
		display: flex;
		position: relative;
		/** Theme Styles */
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background-color: rgba(0, 0, 0, 0.54);
	}
`;

const RadioButton = ({
	className,
	onChange,
	isChecked,
	disabled,
	label,
	name,
	register,
	value,
	cypressClass,
	...props
}) => {
	const [containerClass, setContainerClass] = useState(className);

	useEffect(() => {
		if (disabled) {
			setContainerClass((prevClass) => {
				if (prevClass.indexOf('disabled') < 0) {
					return prevClass + ' disabled';
				}
				return prevClass;
			});
		} else {
			setContainerClass((prevClass) => {
				return prevClass.replace('disabled', '');
			});
		}
	}, [disabled]);

	const finalContainerClass = CleanUpClassName(containerClass);
	return (
		<StyledRadioButton className={finalContainerClass} data-cy={cypressClass}>
			<input
				checked={isChecked}
				onChange={onChange}
				disabled={disabled}
				type='radio'
				name={name}
				value={value}
				ref={register}
				{...props}
			/>
			<span className='radio-border'>
				<span className='radio-background'></span>
			</span>
			<div className='label'>{label}</div>
		</StyledRadioButton>
	);
};

RadioButton.propTypes = {
	/**
	 * class name for container label
	 */
	className: PropTypes.string,
	/**
	 * If true, the button will be checked
	 */
	isChecked: PropTypes.bool.isRequired,
	/**
	 * If true, the radio will be disabled.
	 */
	disabled: PropTypes.bool,
	/**
	 * The value that gets displayed to the user next to the radio button.
	 */
	label: PropTypes.node,
	/**
	 * 	Name attribute of the input element.
	 */
	name: PropTypes.string,
	/**
	 * Callback fired when the state is changed.
	 */
	onChange: PropTypes.func.isRequired,
	/**
	 * A function that gets passed for the useForm hook from react-hook-form to register an input
	 */
	register: PropTypes.func,
	/**
	 * The value of the component.
	 */
	value: PropTypes.string,
	/**
	 * Cypress name
	 */
	cypressClass: PropTypes.string
};
RadioButton.defaultProps = {
	className: '',
	isChecked: false,
	disabled: false,
	name: 'radio',
	value: '',
	label: '',
	onChange: () => {},
	cypressClass: 'radio'
};
export default RadioButton;
