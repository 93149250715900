import React, { useCallback, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import useEventListener from '../../../hooks/useEventListener';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

const PopoverContainer = styled.div`
	display: inline-block;
	vertical-align: text-bottom;
	a {
		cursor: pointer;
	}
`;

const Tooltip = ({ children, tooltipPosition, tooltipContent }) => {
	const [showPopover, setShowPopover] = useState(false);
	const [position, setPosition] = useState({});

	// Create a ref that we add to the element for which we want to detect outside clicks
	const containerRef = useRef();
	const contentRef = useRef();

	// Call hook passing in the ref and a function to call on outside click
	useOnClickOutside(containerRef, () => setShowPopover(false));

	const popoverStyle = {
		...position,
		visibility: showPopover ? 'visible' : 'hidden',
		opacity: showPopover ? 1 : 0,
		padding: '9px 14px',
		display: 'block',
		position: 'fixed',
		marginTop: '12px',
		transition: 'opacity 0.4s, visibility 0.4s',
		backgroundColor: '#fff',
		border: '1px solid #ccc',
		fontSize: '14px',
		zIndex: 1051,
		maxWidth: '276px',
		letterSpacing: '0.7px',
		color: '#444444'
	};

	// Event handler utilizing useCallback ...
	// ... so that reference never changes.
	const handler = useCallback(() => {
		setShowPopover(false);
	}, []);

	// Add event listener using our hook
	useEventListener('scroll', handler);

	function togglePopover(e) {
		e.preventDefault();

		setShowPopover(!showPopover);

		setPositions();
	}

	function setPositions() {
		const contentReact = contentRef.current.getBoundingClientRect();
		const targetRect = containerRef.current.getBoundingClientRect();

		const newPosition = getPosition(tooltipPosition, contentReact, targetRect);

		setPosition(newPosition);
	}

	const renderPopover = () => {
		return createPortal(
			<div
				className={`popover fade in ${tooltipPosition}`}
				style={popoverStyle}
				ref={contentRef}
			>
				<div className='arrow' />
				<div className='popover-content'>{tooltipContent}</div>
			</div>,
			document.body
		);
	};

	return (
		<PopoverContainer ref={containerRef} className='popover-container'>
			<a onClick={(e) => togglePopover(e)}>{children}</a>
			{renderPopover()}
		</PopoverContainer>
	);
};

const getPosition = (placement, elRect, targetRect) => {
	switch (placement) {
		case 'top':
			return getTopPosition(elRect, targetRect);
		case 'right':
			return getRightPosition(elRect, targetRect);
		case 'bottom':
			return getBottomPosition(elRect, targetRect);
		case 'left':
			return getLeftPosition(elRect, targetRect);
		default:
			return getBottomPosition(elRect, targetRect);
	}
};

const getTopPosition = (elRect, targetRect) => {
	return {
		left: targetRect.left + targetRect.width / 2 - elRect.width / 2,
		top: targetRect.top - elRect.height
	};
};

const getRightPosition = (elRect, targetRect) => {
	return {
		left: targetRect.left + targetRect.width,
		top: targetRect.top + targetRect.height / 2 - elRect.height / 2
	};
};

const getBottomPosition = (elRect, targetRect) => {
	return {
		left: targetRect.left + targetRect.width / 2 - elRect.width / 2,
		top: targetRect.top + targetRect.height
	};
};

const getLeftPosition = (elRect, targetRect) => {
	return {
		left: targetRect.left - elRect.width,
		top: targetRect.top + targetRect.height / 2 - elRect.height / 2
	};
};

Tooltip.defaultProps = {
	children: null,
	tooltipPosition: 'bottom',
	tooltipContent: ''
};
Tooltip.propTypes = {
	children: PropTypes.node,
	tooltipPosition: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
	tooltipContent: PropTypes.node
};
export default Tooltip;
