import React, { Fragment, useRef } from 'react';

import { Divider } from '../../atoms/divider';
import { EmotionBreakpoint } from '../../../utils/EmotionBreakpoint';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import useLockBodyScroll from '../../../hooks/useLockBodyScroll';
import useModalOpen from '../../../hooks/useModalOpen';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

const StyledModalWrapper = styled.div`
	position: fixed;
	top: ${(props) => (props.modalWrapperTop ? props.modalWrapperTop : '100px')};
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1050;
	margin: 0;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	width: 100%;
	${EmotionBreakpoint('mobile')} {
		top: 15px;
	}
`;

const StyledModalBackdrop = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 0;
	/** Theme Background */
	background-color: #222222;
	opacity: 0.85;
`;

const StyledModalContainer = styled.div`
	width: 100%;
	margin: 16px auto;
	z-index: 1;
	position: relative;
	border-radius: 3px;

	/** Theme */
	max-width: ${(props) => (props.modalSize ? props.modalSize : '540px')};
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : ''};
	pointer-events: ${(props) =>
		props.backgroundColor === 'none' ? 'none' : ''};

	.modal-close {
		border: 0;
	}

	.modal-dialog {
		padding: 60px 50px 40px;

		${EmotionBreakpoint('mobile')} {
			padding: 60px 15px 25px;
		}
	}

	.modal-content {
		box-shadow: none !important;
	}

	.modal-header {
		display: flex;
		align-items: center;
		justify-content: start;
		text-align: center;
		margin-bottom: 15px;
	}
	.modal-body {
		margin-top: ${(props) => (props.modalHeader ? '35px' : '0')};
	}
`;

const Modal = ({
	showModal,
	toggleModal,
	modalHeader,
	children,
	modalSize,
	disableOnClickOutside,
	disableClose,
	backgroundColor,
	cypressClass,
	modalClass,
	closeModalCallback,
	modalWrapperTop
}) => {
	// Create a ref that we add to the element for which we want to detect outside clicks
	const ref = useRef();

	// Call hook passing in the ref and a function to call on outside click
	useOnClickOutside(
		ref,
		disableOnClickOutside ? () => {} : () => toggleModal()
	);

	// Call hook to lock body scroll
	useLockBodyScroll(showModal);

	// Call hook to toggle 'modal-open' class
	useModalOpen(showModal);

	const renderModal = () => {
		return createPortal(
			<StyledModalWrapper modalWrapperTop={modalWrapperTop}>
				<StyledModalContainer
					className={`modal fade ${showModal ? 'in' : ''} ${modalClass}`}
					aria-modal
					modalSize={modalSize}
					aria-hidden={`${showModal}`}
					tabIndex={-1}
					backgroundColor={backgroundColor}
					data-cy={cypressClass}
				>
					<div ref={ref} className='modal-dialog' role='dialog'>
						{!disableClose ? (
							<span
								onClick={() => {
									toggleModal();
									closeModalCallback && closeModalCallback();
								}}
								title='Close Modal'
								className='modal-close'
							></span>
						) : null}

						<div className='modal-content'>
							{modalHeader ? (
								<>
									<div
										className='modal-header'
										data-cy={`${cypressClass}-header`}
									>
										<h5 className='mb-20'>{modalHeader}</h5>
									</div>

									{/* <Divider /> */}
								</>
							) : null}
							<div className='modal-body'>{children}</div>
						</div>
					</div>
				</StyledModalContainer>
				<StyledModalBackdrop
					className={`modal-backdrop modal-stack fade ${showModal ? 'in' : ''}`}
				/>
			</StyledModalWrapper>,
			document.body
		);
	};

	return <Fragment>{showModal ? renderModal() : false}</Fragment>;
};

Modal.defaultProps = {
	showModal: false,
	toggleModal: () => {},
	modalHeader: null,
	children: null,
	disableOnClickOutside: false,
	disableClose: false,
	modalSize: '540px',
	backgroundColor: '#ffffff',
	cypressClass: 'modal',
	closeModalCallback: null,
	modalClass: ''
};
Modal.propTypes = {
	modalSize: PropTypes.string,
	showModal: PropTypes.bool,
	toggleModal: PropTypes.func,
	modalHeader: PropTypes.node,
	children: PropTypes.node,
	disableOnClickOutside: PropTypes.bool,
	disableClose: PropTypes.bool,
	backgroundColor: PropTypes.string,
	cypressClass: PropTypes.string,
	closeModalCallback: PropTypes.func,
	modalClass: PropTypes.string
};
export default Modal;
