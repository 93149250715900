import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

const StyledSvg = styled.svg`
	circle {
		animation: 1s spin ease-in-out infinite;
	}

	#top-right {
		animation-delay: -0.75s;
	}
	#bottom-right {
		animation-delay: -0.5s;
	}
	#bottom-left {
		animation-delay: -0.25s;
	}
	@keyframes spin {
		3% {
			fill-opacity: 1;
		}
		50% {
			fill-opacity: 0;
		}
		97% {
			fill-opacity: 1;
		}
	}
`;

const Spinner = ({ circleRadius }) => {
	const topLeftDotCX = circleRadius * 2;
	const topLeftDotCY = circleRadius * 2;
	const separator = circleRadius > 10 ? 10 : 6;
	const width = circleRadius * 6 + separator;
	const height = circleRadius * 6 + separator;
	const calculatedViewBox = `0 0 ${width} ${height}`;

	return (
		<StyledSvg
			width={width}
			height={height}
			viewBox={calculatedViewBox}
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle
				id='top-left'
				cx={topLeftDotCX}
				cy={topLeftDotCY}
				fill='#F4B7C8'
				r={circleRadius}
			/>
			<circle
				id='top-right'
				cx={topLeftDotCX + circleRadius * 2 + separator}
				cy={topLeftDotCY}
				fill='#E35A81'
				r={circleRadius}
			/>
			<circle
				id='bottom-right'
				cx={topLeftDotCX + circleRadius * 2 + separator}
				cy={topLeftDotCY + circleRadius * 2 + separator}
				fill='#C60F4C'
				r={circleRadius}
			/>
			<circle
				id='bottom-left'
				cx={topLeftDotCX}
				cy={topLeftDotCY + circleRadius * 2 + separator}
				fill='#9C0745'
				r={circleRadius}
			/>
		</StyledSvg>
	);
};

Spinner.defaultProps = {
	circleRadius: 5
};
Spinner.propTypes = {
	/**
	 * the circle radius for the dots
	 */
	circleRadius: PropTypes.number
};

export default Spinner;
