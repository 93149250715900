import React, { useState } from 'react';

import { GetCardInfo } from '../../../../utils/GetCardInfo';
import PropTypes from 'prop-types';
import { TextInput } from '../../text_input';
import styled from '@emotion/styled';

const StyledTextInput = styled(TextInput)`
	letter-spacing: 1.8px;
`;
const CreditCardNumberInput = ({ errors, register }) => {
	const [cardLength, setCardLength] = useState(16);
	let error = false;
	let helperText = '';
	if (errors.creditCardNumber) {
		error = true;
		helperText = errors.creditCardNumber.message;
	}
	const handleCreditCardInput = (e) => {
		let inputField = e.target;
		let inputValue = e.target.value.replace(/\D/g, '');
		const { cardLength } = GetCardInfo(inputValue);
		if (cardLength === 16 || cardLength === 15) {
			setCardLength(cardLength);
		}
		inputField.value = inputValue.substring(0, cardLength);
	};
	const checkValidCreditCard = (cardNumber) => {
		const invalidCreditCardMessage =
			'Your credit card number is invalid, please use another credit card.';
		const { isValid } = GetCardInfo(cardNumber);
		if (isValid) {
			return true;
		} else {
			return invalidCreditCardMessage;
		}
	};
	return (
		<StyledTextInput
			cypressClass='credit-card-number'
			helperText={helperText}
			maxLength={cardLength}
			onChange={handleCreditCardInput}
			type='text'
			name='creditCardNumber'
			label='Credit Card Number'
			error={error}
			register={register({
				required: 'Missing Credit Card Number',
				pattern: {
					value: /^((4\d{3})|(5[1-5]\d{2}))(-?|\040?)(\d{4}(-?|\040?)){3}|^(3[4,7]\d{2})(-?|\040?)\d{6}(-?|\040?)\d{5}/,
					message: `Please make sure that your credit card number is ${cardLength} digits`
				},
				validate: {
					isValidCreditCard: (value) => checkValidCreditCard(value)
				}
			})}
		></StyledTextInput>
	);
};

CreditCardNumberInput.defaultProps = {
	errors: {},
	register: () => {}
};
CreditCardNumberInput.propTypes = {
	/**
	 * Object containing the errors from this input field.
	 */
	errors: PropTypes.object,
	/**
	 * A function that gets passed for the useForm hook from react-hook-form to register an input
	 *
	 */
	register: PropTypes.func.isRequired
};
export default CreditCardNumberInput;
