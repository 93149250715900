import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

const CheckboxWrapper = styled.div`
	display: inline-block;
	.checkbox-container {
		.checkbox-visible {
			display: flex;
			align-items: center;
			cursor: pointer;
		}

		input {
			position: absolute;
			opacity: 0;
			height: 0;
			width: 0;

			:checked ~ .checkbox-visible .checkbox-svg svg .check {
				opacity: 1;
			}

			:disabled ~ .checkbox-visible {
				cursor: not-allowed;
			}
		}
	}

	.checkbox-label {
		margin-left: 10px;
	}

	.check {
		opacity: 0;
	}

	svg {
		fill: #545454;
	}
`;

const Checkbox = ({
	className,
	name,
	onChange,
	label,
	register,
	value,
	disabled,
	cypressClass,
	...props
}) => {
	return (
		<CheckboxWrapper>
			<label className='checkbox-container' data-cy={cypressClass}>
				<input
					onChange={onChange}
					type='checkbox'
					name={name}
					value={value}
					className={className}
					ref={register}
					disabled={disabled}
					{...props}
				></input>
				<div className='checkbox-visible'>
					<div className='checkbox-svg'>
						<svg
							width='18'
							height='18'
							viewBox='0 0 18 18'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								className='box'
								d='M16 2V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z'
							/>
							<path
								className='check'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM14.99 6L13.58 4.58L6.99 11.17L4.41 8.6L2.99 10.01L6.99 14L14.99 6Z'
							/>
						</svg>
					</div>
					{label ? <div className='checkbox-label'>{label}</div> : null}
				</div>
			</label>
		</CheckboxWrapper>
	);
};

Checkbox.propTypes = {
	/**
	 * Disable the checkbox from being checked when value is true. Update styling to appear un-clickable.
	 */
	disabled: PropTypes.bool,
	/**
	 * Adds a string of classnames to the checkbox in order to style the checkbox a bit different than its default
	 *
	 */
	className: PropTypes.string,
	/**
	 * The value that gets displayed to the user next to the check box.
	 */
	label: PropTypes.node,
	/**
	 *
	 * The name associated with this input
	 */
	name: PropTypes.string.isRequired,
	/**
	 * A function to execute when the checkbox is toggled
	 */
	onChange: PropTypes.func,
	/**
	 *
	 * Method that allows you to register input Ref and validation rules into React Hook Form.
	 */
	register: PropTypes.func,
	/**
	 *
	 * The value of the component when checked.
	 */
	value: PropTypes.string,
	/**
	 * Cypress name
	 */
	cypressClass: PropTypes.string
};
Checkbox.defaultProps = {
	name: 'checkbox',
	onChange: () => {},
	label: null,
	disabled: false,
	cypressClass: 'checkbox'
};
export default Checkbox;
