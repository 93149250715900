import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

const StyledDiv = styled.div`
	height: 1px;
	background-color: #e3e3e3;
`;

/**
 *
 * @param {String} ClassName
 * @returns a horizontal line
 */

const Divider = ({ className }) => {
	return <StyledDiv className={className} />;
};

Divider.defaultProps = {
	className: null
};
Divider.propTypes = {
	/**
	 *  a className for the divider
	 */
	className: PropTypes.string
};
export default Divider;
