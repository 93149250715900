import PropTypes from 'prop-types';
import React from 'react';
import { TextInput } from '../../text_input';
import styled from '@emotion/styled';

const StyledTextInput = styled(TextInput)`
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;
const CVVInput = ({ errors, register, maxLength }) => {
	let error = false;
	let helperText = '';
	if (errors.cvv) {
		error = true;
		helperText = errors.cvv.message;
	}

	const handleCvvInput = (e) => {
		let cvvInput = e.target;
		let inputValue = e.target.value;
		cvvInput.value = inputValue.replace(/\D/g, '').substr(0, maxLength);
	};

	const checkCvvInput = (cvvInput) => {
		let inputLength = cvvInput.length;

		if (!isNaN(cvvInput) && inputLength === maxLength) {
			return true;
		} else {
			return `Please make sure that your CVV number is ${maxLength} digits`;
		}
	};
	return (
		<>
			<StyledTextInput
				cypressClass='cvv'
				onChange={handleCvvInput}
				maxLength={maxLength}
				error={error}
				type='text'
				label='CVV'
				name='cvv'
				helperText={helperText}
				register={register({
					required: 'Missing CVV',
					validate: {
						validateCvv: (cvv) => checkCvvInput(cvv)
					}
				})}
			/>
		</>
	);
};

CVVInput.defaultProps = {
	maxLength: 3,
	errors: {},
	register: () => {}
};
CVVInput.propTypes = {
	/** The max length of the CVV input field */
	maxLength: PropTypes.number,
	/**
	 * Object containing the errors from this input field.
	 */
	errors: PropTypes.object,
	/**
	 * A function that gets passed for the useForm hook from react-hook-form to register an input
	 *
	 */
	register: PropTypes.func.isRequired
};
export default CVVInput;
